import React from "react";
import Layout from "../components/layout";
import { SEO, SeoObject } from "../components/seo";

const NotFoundPage = () => {
  let meta: SeoObject = {
    description: "404 Not Found",
    title: "404: Not found",
  };
  return (
    <Layout>
      <SEO meta={meta} />
      <div className="full-bleed wrapper row-gap">
        <h1>404</h1>
        <h2>Not Found</h2>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
